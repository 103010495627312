import * as indexStyle from "./index.module.scss"
import React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'
import { Link } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import Helmet from 'react-helmet';

export default function Index({ data }) {
  const makerList = data.allMdx.nodes // All the cidermakers
  const numberOfReviews = data.reviewsCount

  return (
    <Layout pageTitle="Cider With Evans">
      <Helmet>
                <title>Cider with Evans. Cider/perry review website</title>
                <meta name="google-site-verification" content="NdorwpVjuv42plvgHBArVxZlB9pWvFrjWFvARuAFH0Y" />
                <meta name="description" content="Cider with Evans is a cider reviews website covering around 150 ciders so far.  New ciders are added a few times a week."/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta property="og:image" content="https://ciderwithevans.com/evans.png"/>
                <meta property="og:title" content="Cider with Evans"/>
                <meta property="og:description" content="Cider reviews site covering around 150 ciders so far.  New ciders are added a few times a week."/>
                <meta property="og:image:width" content="600"/>
                <meta property="og:image:height" content="949"/>
      </Helmet>
      <div className={indexStyle.gallery}>
        {
          makerList.map((node) => (
            <div  className={indexStyle.tile}>
              <Link to={node.slug}>
                <GatsbyImage
                  image={getImage(node.frontmatter.maker_image)}
                  alt={"Reviews for " + node.frontmatter.title  }/>
                  <div className={indexStyle.overlay}>
                {node.frontmatter.published !== true &&<StaticImage src="../images/coming soon.png" alt='coming soon' />}
              </div>
              </Link>     
            </div>
          ))
        }
      </div>

      <div className={indexStyle.reviews}>
        Reviews so far: {numberOfReviews.totalCount}
      </div>

    </Layout>
  )
}

export const query2 = graphql`
  query  {
    allMdx (filter: {frontmatter: {page_type: {eq: "maker"}}} sort: {fields: frontmatter___maker_order}){
      nodes {
        frontmatter {
          title
          published
          maker_image
          {
            childImageSharp 
              {
              gatsbyImageData
              }
          }
          maker_order
        }
        id
        slug
        body
      }
    }
    reviewsCount: allMdx (filter: {frontmatter: { page_type: {eq: "review"}, rating: {ne: null}}})
    {
      totalCount
    }  
  }
  `
